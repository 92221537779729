import React, { useState } from "react";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FiFilter } from 'react-icons/fi'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import { textFilter, customFilter } from 'react-bootstrap-table2-filter';
import RangeDurationFilter from '../components/RangeDurationFilter';
import RangeFilter from "../components/RangeFilter";
const RecordingTableComponent = (props) => {
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [showAgentFilter, setShowAgentFilter] = useState(false);
    const [showDurationFilter, setShowDurationFilter] = useState(false);
    const [showCallerFilter, setShowCallerFilter] = useState(false);
    const [showRecordingScopeFilter, setRecordingScopeFilter] = useState(false);
    const [showDirectionFilter, setShowDirectionFilter] = useState(false);
    const [showTypeFilter, setShowTypeFilter] = useState(false);
    const [showCallIdFilter, setShowCallIdFilter] = useState(false);
    let AgentFilter;
    let CallerFilter;
    let recordingScopeFilter;
    let directionFilter;
    let typeFilter;
    let callIdFilter;

    const options = {
        // pageStartIndex: 0,
        sizePerPage: 20,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
    };
    const selectOptionsDirection = {
        'IN': 'IN',
        'OUT': 'OUT'
    };
    const selectOptionsType = {
        'phone': 'phone',
        'user': 'user'
    }
    const toggleDateFilter = () => {
        if (showDateFilter) {
            setShowDateFilter(false);

        } else {
            setShowDateFilter(true)
        }
    }
    const toggleDurationFilter = () => {
        if (showDurationFilter) {
            setShowDurationFilter(false)
        } else {
            setShowDurationFilter(true)
        }
    }
    const toggleDirectionFilter = () => {
        if (showDirectionFilter) {
            setShowDirectionFilter(false)
        } else {
            setShowDirectionFilter(true)
        }
    }
    const toggleAgent = () => {
        if (showAgentFilter) {
            setShowAgentFilter(false)
            if (typeof (AgentFilter) === 'function') {
                AgentFilter('');
            }
        } else {
            setShowAgentFilter(true)
        }
    }
    const toggleCallIdFilter = () => {
        if (showCallIdFilter) {
            setShowCallIdFilter(false)
            if (typeof (callIdFilter) === 'function') {
                callIdFilter('');
            }
        } else {
            setShowCallIdFilter(true)
        }
    }
    const toggleCallerFilter = () => {
        if (showCallerFilter) {
            setShowCallerFilter(false);
            if (typeof (CallerFilter) === 'function') {
                CallerFilter();
            }
        } else {
            setShowCallerFilter(true)
        }
    }
    const toggleRecordingScopeFilter = () => {
        if (showRecordingScopeFilter) {
            setRecordingScopeFilter(false)
            if (typeof (recordingScopeFilter) === 'function') {
                recordingScopeFilter('');
            }
        } else {
            setRecordingScopeFilter(true)
        }
    }
    const toggleTypeFilter = () => {
        if (showTypeFilter) {
            setShowTypeFilter(false)
            if (typeof (recordingScopeFilter) === 'function') {
                typeFilter('');
            }
        } else {
            setShowTypeFilter(true)
        }
    }
    const ClearedfilterDuration = (filterVals, data) => {
        return data;
    }
    const stringToDuration = (string) => {
        const timeString = string; // input string

        const arr = timeString.split(":"); // splitting the string by colon

        const seconds = arr[0] * 60 + (+arr[1]);
        return seconds
    }
    const filterDuration = (filterVals, data) => {
        let minMinutes = filterVals.minMinutes;
        let maxMinutes = filterVals.maxMinutes;
        let minSeconds = filterVals.minSeconds;
        let maxSeconds = filterVals.maxSeconds;
        if (minMinutes === "") {
            minMinutes = 0;
        }
        if (maxMinutes === "") {
            maxMinutes = 0;
        }
        if (minSeconds === "") {
            minSeconds = 0;
        }
        if (maxSeconds === "") {
            maxSeconds = 0;
        }

        let minDuration = (minMinutes * 60) + parseFloat(minSeconds)
        let maxDuration = (maxMinutes * 60) + parseFloat(maxSeconds)
        if (isNaN(maxDuration)) {
            maxDuration = 0;
        }
        if (isNaN(minDuration)) {
            minDuration = 0;
        }
        let formatedFilterVals = {
            min: minDuration,
            max: maxDuration
        }
        const filteredData = data.filter(
            DurationValue =>
                (formatedFilterVals.min && (stringToDuration(DurationValue.Duration) >= formatedFilterVals.min)) &&
                (formatedFilterVals.max && (stringToDuration(DurationValue.Duration) <= formatedFilterVals.max))
        );

        return filteredData;
    }
    const ClearedDateFilter = (filterVals, data) => {
        return data;
    }
    const DateFilter = (filterVals, data) => {
        let dateMin = new Date(filterVals.min).getTime() / 1000;
        let dateMax = new Date(filterVals.max).getTime() / 1000;
        let formatedFilterVals = {
            min: dateMin,
            max: dateMax
        }
        const filteredData = data.filter(
            product =>
                ((formatedFilterVals.min && (new Date(product.Date).getTime() / 1000) >= formatedFilterVals.min) ||
                    !filterVals.min) &&
                ((formatedFilterVals.max && (new Date(product.Date).getTime() / 1000) <= formatedFilterVals.max) || !formatedFilterVals.max)
        );
        return filteredData;
    };
    const state = {
        columns: [
            {
                dataField: 'Recording',
                text: 'Recording',
            },
            {
                dataField: 'direction',
                text: < div ><span>Direction</span><FiFilter className='filter-icon' onClick={() => { toggleDirectionFilter() }} /></div >,
                filter: showDirectionFilter ? selectFilter({
                    placeholder: 'Select direction',
                    options: selectOptionsDirection,
                    getFilter: (filter) => {
                        // qualityFilter was assigned once the component has been mounted.
                        directionFilter = filter;
                    }
                })
                    :
                    null
            },
            {
                dataField: "recordingScope",
                text: < div ><span>Recording scope</span><FiFilter className='filter-icon' onClick={() => { toggleRecordingScopeFilter() }} /></div >,
                filter: showRecordingScopeFilter ? textFilter({
                    placeholder: 'Enter recording scope',
                    getFilter: (filter) => {
                        recordingScopeFilter = filter;
                    }
                }) : null,
            },
            {
                dataField: 'id',
                text: <div><span>Caller Id</span><FiFilter className='filter-icon' onClick={() => { toggleCallerFilter() }} /></div>,
                filter: showCallerFilter ? textFilter({
                    placeholder: 'Enter Caller name',
                    getFilter: (filter) => {
                        CallerFilter = filter;
                    }
                }) : null,

            },
            {
                dataField: 'Duration',
                text: <div><span>Duration</span><FiFilter className='filter-icon' onClick={() => { toggleDurationFilter() }} /></div>,
                filter: showDurationFilter ? customFilter({
                    onFilter: filterDuration,
                }) : customFilter({
                    onFilter: ClearedfilterDuration,
                }),
                filterRenderer: (onFilter, column) => (
                    <div>
                        {showDurationFilter ? <RangeDurationFilter onFilter={onFilter} column={column} /> : null}
                    </div>
                ),
            },
            {
                dataField: 'Agent_name',
                text: <div><span>Agent name</span><FiFilter className='filter-icon' onClick={() => { toggleAgent() }} /></div>,
                filter: showAgentFilter ? textFilter({
                    placeholder: 'Enter Agent name',
                    getFilter: (filter) => {
                        AgentFilter = filter;
                    }
                }) : null,
            },
            {
                dataField: 'Date',
                text: <div><span>Date</span><FiFilter className='filter-icon' onClick={() => { toggleDateFilter() }} /></div>,
                filter: showDateFilter ? customFilter({
                    onFilter: DateFilter
                }) : customFilter({
                    onFilter: ClearedDateFilter
                }),
                filterRenderer: (onFilter, column) => (
                    <div>
                        {showDateFilter ? <RangeFilter onFilter={onFilter} column={column} /> : null}
                    </div>

                ),


            },
            {
                dataField: 'Timestamp',
                sort: true,
                sortFunc: (a, b, order) => {
                    if (order === "asc") {
                        return a - b;
                    } else if (order === "desc") {
                        return b - a;
                    }
                },
                text: <div><span>Timestamp</span><FiFilter className='filter-icon' /></div>,
                hidden: true
            },
            /*
            {
                dataField: 'Call_type',
                text: <div><span>Call type</span><FiFilter className='filter-icon' onClick={() => { toggleTypeFilter() }} /></div>,
                filter: showTypeFilter ? selectFilter({
                    placeholder: 'Select type',
                    options: selectOptionsType,
                    getFilter: (filter) => {
                        // qualityFilter was assigned once the component has been mounted.
                        typeFilter = filter;
                    }
                })
                    :
                    null

            },
            */
            {
                dataField: 'Call_id',
                text: <div><span>Call id</span><FiFilter className='filter-icon' onClick={() => { toggleCallIdFilter() }} /></div>,
                filter: showCallIdFilter ? textFilter({
                    placeholder: 'Enter call id',
                    defaultValue: props.defaultCall ? props.defaultCall : null,
                    getFilter: (filter) => {
                        typeFilter = filter;
                    }
                }) : null,


            }

        ]
    }

    const defaultSortedBy = [{
        dataField: "Timestamp",//
        order: "desc"  // or asc
    }];
    // console.log('props.tableItems====>', props.tableItems)
    return (
        <div id="callflow">
            <div style={{}}>
                <BootstrapTable
                    hover
                    wrapperClasses="table-responsive"
                    keyField="Call_id"
                    data={props.tableItems}
                    columns={state.columns}
                    pagination={paginationFactory(options)}
                    filter={filterFactory()}
                    className="table-responsive"
                    defaultSorted={defaultSortedBy}
                />
            </div>
        </div >
    )
}
export default RecordingTableComponent;